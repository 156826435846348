import ApiService from "./ApiService";

export async function apiGetProducts(data) {
  return ApiService.fetchData({
    url: "/v1/stripe/products",
    method: "get",
    data,
  });
}

// export async function apiDeleteProducts(data) {
//   return ApiService.fetchData({
//     url: "/stripe/products/delete",
//     method: "delete",
//     data,
//   });
// }

// export async function apiGetSalesProduct(params) {
//   return ApiService.fetchData({
//     url: "/sales/product",
//     method: "get",
//     params,
//   });
// }

// export async function apiPutSalesProduct(data) {
//   return ApiService.fetchData({
//     url: "/sales/products/update",
//     method: "put",
//     data,
//   });
// }

// export async function apiCreateSalesProduct(data) {
//   return ApiService.fetchData({
//     url: "/sales/products/create",
//     method: "post",
//     data,
//   });
// }

// export async function apiGetSalesOrders(params) {
//   return ApiService.fetchData({
//     url: "/sales/orders",
//     method: "get",
//     params,
//   });
// }

// export async function apiDeleteSalesOrders(data) {
//   return ApiService.fetchData({
//     url: "/sales/orders/delete",
//     method: "delete",
//     data,
//   });
// }

// export async function apiGetSalesOrderDetails(params) {
//   return ApiService.fetchData({
//     url: "/sales/orders-details",
//     method: "get",
//     params,
//   });
// }
