import { createSlice } from "@reduxjs/toolkit";

interface Navigation {
  to: string;
  options: object | undefined;
  appendRedirectUrl: boolean | undefined;
}

const initialState = {
  to: "",
  options: {},
  appendRedirectUrl: false,
} as Navigation;

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setNavigate: (state, action) => action.payload,
    onNavigateSuccess: (state, action) => initialState,
  },
});

export const { setNavigate, onNavigateSuccess } = navigationSlice.actions;

export default navigationSlice.reducer;
