import { theme as twTheme } from "twin.macro";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillWarning,
  AiFillQuestionCircle,
} from "react-icons/ai";
const twColor = twTheme`colors`;

// ? If changes are made to below list update sql queries on backend as well:
export const metricKeysGroup = {
  noop_count: "error",
  failed_count: "error",
  placed_count: "success",
  already_in_position_count: "success",
  inactive_subscription_count: "not_subscribed",
  wallet_empty_count: "support",
  order_qty_too_small_count: "support",
  invalid_leverage_count: "error",
  multiple_positions_open_count: "support",
  position_not_in_one_way_mode_count: "support",
  in_hedge_mode_count: "support",
  signal_out_of_bounds_count: "error",
  assertion_error_count: "error",
  insufficient_balance_count: "support",
  params_error_count: "error",
  reduce_only_position_count: "support",
  request_timeout_count: "error",
  regulatory_restrictions_count: "support",
  api_key_expired_count: "support",
  invalid_request_error_count: "error",
  invalid_api_key_count: "support",
  failed_request_error_count: "error",
};

export const metricKeyAliases = {
  placed_count: "Placed",
  already_in_position_count: "Already in Position",
  inactive_subscription_count: "Not Subscribed",
  wallet_empty_count: "Wallet Empty",
  order_qty_too_small_count: "Insufficient Funds",
  invalid_leverage_count: "Invalid Leverage",
  multiple_positions_open_count: "Multiple Positions Open",
  position_not_in_one_way_mode_count: "Open Position Not in One-Way Mode",
  in_hedge_mode_count: "In Hedge Mode",
  signal_out_of_bounds_count: "Signal Out of Bounds",
  assertion_error_count: "Assertion Error",
  insufficient_balance_count: "Insufficient Balance",
  params_error_count: "Parameter Error",
  reduce_only_position_count: "Reduce Only Position Mode",
  request_timeout_count: "Request Timeout",
  regulatory_restrictions_count: "Regulatory Restrictions",
  api_key_expired_count: "API Key Expired",
  invalid_request_error_count: "Invalid Request Error",
  invalid_api_key_count: "Invalid API Key",
  failed_request_error_count: "Failed Request Error",
  noop_count: "No-op",
  failed_count: "Failed",
};

const mapMetricsToGroups = () => {
  const groupedKeys = {
    success: [],
    support: [],
    error: [],
    not_subscribed: [],
  };
  Object.keys(metricKeysGroup).forEach((key) => {
    groupedKeys[metricKeysGroup[key]].push(key);
  });
  return groupedKeys;
};

export const metricGroupsWidgetOptions = (
  metricsGroupedTotals,
  metricsGroupedPerSignal
) => {
  const summaryGraphsdata = {
    success: {
      label: "Success",
      groupKey: "success",
      color: metricGroupColors["success"],
      value: metricsGroupedTotals["success"],
      data: [],
    },
    support: {
      label: "Support",
      groupKey: "support",
      color: metricGroupColors["support"],
      value: metricsGroupedTotals["support"],
      data: [],
    },
    error: {
      label: "Error",
      groupKey: "error",
      color: metricGroupColors["error"],
      value: metricsGroupedTotals["error"],
      data: [],
    },
    not_subscribed: {
      label: "Not Subscribed",
      groupKey: "not_subscribed",
      color: metricGroupColors["not_subscribed"],
      value: metricsGroupedTotals["not_subscribed"],
      data: [],
    },
  };

  if (
    metricsGroupedPerSignal !== undefined &&
    metricsGroupedPerSignal.length > 0
  ) {
    metricsGroupedPerSignal.forEach((item) => {
      summaryGraphsdata["success"]["data"].push({
        x: item.timestamp + " GMT",
        y: item["success"],
      });
      summaryGraphsdata["support"]["data"].push({
        x: item.timestamp + " GMT",
        y: item["support"],
      });
      summaryGraphsdata["error"]["data"].push({
        x: item.timestamp + " GMT",
        y: item["error"],
      });
      summaryGraphsdata["not_subscribed"]["data"].push({
        x: item.timestamp + " GMT",
        y: item["not_subscribed"],
      });
    });
  }

  return summaryGraphsdata;
};

export const metricGroups = mapMetricsToGroups();

export const metricGroupColors = {
  success: twColor.green["500"],
  support: twColor.yellow["400"],
  error: twColor.red["500"],
  not_subscribed: twColor.slate["500"],
};

export const metricGroupStatusIcons = {
  success: AiFillCheckCircle,
  support: AiFillWarning,
  error: AiFillCloseCircle,
  not_subscribed: AiFillQuestionCircle,
};

// if undefined, fallback to status_message value from backend
export const metricStatusMessage = {
  PLACED: undefined,
  ALREADY_IN_POSITION:
    "Customer's position is already within margin for the desired position",
  INACTIVE_SUBSCRIPTION: undefined,
  WALLET_EMPTY: "Customer's wallet is empty",
  ORDER_QTY_TOO_SMALL: "Customer lacks funds to place current order",
  INVALID_LEVERAGE: "Leverage is not 1",
  MULTIPLE_POSITIONS_OPEN: undefined,
  POSITION_NOT_IN_ONE_WAY_MODE: undefined,
  IN_HEDGE_MODE: undefined,
  SIGNAL_OUT_OF_BOUNDS: undefined,
  ASSERTION_ERROR: "Assertion error encountered. Expand for more information",
  INSUFFICIENT_BALANCE: undefined,
  PARAMS_ERROR: "Parameter error encountered. Expand for more information",
  REDUCE_ONLY_POSITION: "Customer has a reduce only position open",
  REQUEST_TIMEOUT: "Request timed out. Expand for more information",
  REGULATORY_RESTRICTIONS: "Order rejected due to regulatory restrictions",
  API_KEY_EXPIRED: "API key has expired",
  INVALID_REQUEST_ERROR:
    "Invalid request error encountered. Expand for more information",
  INVALID_API_KEY: "Customer's API key is not valid",
  FAILED_REQUEST_ERROR:
    "There was a problem with the request. Expand for more information",
  NOOP: undefined,
  FAILED: "Trade attempt failed. Expand for more information",
};

export const metricStatusExtra = {
  PLACED: undefined,
  ALREADY_IN_POSITION: undefined,
  INACTIVE_SUBSCRIPTION:
    "Customer does not have an active CryptoSea subscription",
  WALLET_EMPTY: undefined,
  ORDER_QTY_TOO_SMALL: undefined,
  INVALID_LEVERAGE: undefined,
  MULTIPLE_POSITIONS_OPEN: undefined,
  POSITION_NOT_IN_ONE_WAY_MODE: undefined,
  IN_HEDGE_MODE: undefined,
  SIGNAL_OUT_OF_BOUNDS: undefined,
  ASSERTION_ERROR: undefined,
  INSUFFICIENT_BALANCE: undefined,
  PARAMS_ERROR: undefined,
  REDUCE_ONLY_POSITION: undefined,
  REQUEST_TIMEOUT: undefined,
  REGULATORY_RESTRICTIONS: undefined,
  API_KEY_EXPIRED: undefined,
  INVALID_REQUEST_ERROR: undefined,
  INVALID_API_KEY: undefined,
  FAILED_REQUEST_ERROR: undefined,
  NOOP: undefined,
  FAILED: undefined,
};
