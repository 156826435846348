import toast from "./toast";
import { Notification } from "components/ui";

const toastNotification = (title, body, options) => {
  toast.push(
    <Notification {...options} title={title}>
      {body}
    </Notification>
  );
};

export default toastNotification;
