import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiGetProducts } from "services/ProductService";

export const getProducts = createAsyncThunk(
  "product/getProducts",
  async (data) => {
    const response = await apiGetProducts(data);
    return response.data;
  }
);

export const deleteProduct = async (data) => {
  // const response = await apiDeleteProducts(data);
  //   return response.data;
  // TODO WIP
  return false;
};

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
};

const dataSlice = createSlice({
  name: "product",
  initialState: {
    loading: false,
    productList: [],
    tableData: initialTableData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.productList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
  },
});

export const { updateProductList, setTableData } = dataSlice.actions;

export default dataSlice.reducer;
