import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  signedIn: false,
};

export const sessionSlice = createSlice({
  name: "auth/session",
  initialState: initialState,
  reducers: {
    onSignInSuccess: (state, action) => {
      state.signedIn = true;
    },
    onSignOutSuccess: (state) => {
      state.signedIn = false;
    },
  },
});

export const { onSignInSuccess, onSignOutSuccess } = sessionSlice.actions;

export default sessionSlice.reducer;
