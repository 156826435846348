import { get } from "lodash";

export const userMetadataPayloadTransform = (user) => {
  return {
    avatar: "",
    authority: get(user, "roles", []),
    fullName: get(user, "fullName", ""),
    email: get(user, "email", ""),
  };
};
