import { createSlice } from "@reduxjs/toolkit";

// todo create transform for subscriber data. this is the data that comes from the server
// convert from snake case to camel case, destructuring, etc
interface Customer {
  [customerId: string]: {
    id: string;
    email: string;
    full_name: string;
    avatar_url: string;
    stripe_id: string;
    created_at: string;
    tradingview_username: string;
    smart_bot_access_until: string;
    premium_indicator_access_until: string;
    premium_indicator_status: string;
    roles: string[];
    has_thirdparty_sso: boolean;
    exclude_from_metrics: boolean;
    exchanges: {
      exchange_name: string;
      connected: boolean;
      is_valid: boolean;
      volume: number;
      pair: number;
      usdt_balance: number;
      btc_balance: number;
      is_primary: boolean;
      assets_distribution: {
        [asset: string]: {
          amount: number;
          price: number;
          total: number;
          ratio: number;
          "24hChange": number;
        };
      };
    };
  };
}

interface TradeMetrics {
  start_datetime: string;
  end_datetime: string;
  grouped_totals?: {
    success?: number;
    support?: number;
    error?: number;
    not_subscribed?: number;
  };
  grouped_per_signal?: Array<{
    success?: number;
    support?: number;
    error?: number;
    not_subscribed?: number;
    timestamp?: string;
  }>;
  metrics_summary?: {
    already_in_position_count?: number;
    api_key_expired_count?: number;
    assertion_error_count?: number;
    failed_count?: number;
    failed_request_error_count?: number;
    wallet_empty_count?: number;
    signal_out_of_bounds_count?: number;
    request_timeout_count?: number;
    regulatory_restrictions_count?: number;
    reduce_only_position_count?: number;
    position_not_in_one_way_mode_count?: number;
    placed_count?: number;
    params_error_count?: number;
    order_qty_too_small_count?: number;
    noop_count?: number;
    multiple_positions_open_count?: number;
    invalid_request_error_count?: number;
    invalid_leverage_count?: number;
    invalid_api_key_count?: number;
    insufficient_balance_count?: number;
    inactive_subscription_count?: number;
    in_hedge_mode_count?: number;
  };
}

interface OrderSubmissions {
  id: number;
  signal_id: number;
  customer_id: string;
  customer_full_name: string;
  order_id?: string;
  created_at: string;
  status: string;
  status_message?: string;
  wallet_balance?: number;
  wallet_available_balance?: number;
  position_side?: string;
  order_side?: string;
  position_value?: number;
  position_entry_price?: number;
  position_size?: number;
  btc_last_price?: number;
  min_trading_qty?: number;
  order_qty?: number;
}

interface DataStore {
  customers: Customer;
  markPrice: string | undefined;
  tradeMetrics: TradeMetrics;
  orderSubmissions: Array<OrderSubmissions>;
  orderSubmissionsTotalCount: number;
}

const initialState: DataStore = {
  customers: {},
  markPrice: undefined,
  tradeMetrics: {
    start_datetime: "",
    end_datetime: "",
    metrics_summary: {},
    grouped_totals: {},
    grouped_per_signal: [],
  },
  orderSubmissions: [],
  orderSubmissionsTotalCount: 20, // used for pagination start with 2 pages
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setCustomerData: (state, action) => {
      state.customers = {
        ...state.customers,
        ...action.payload,
      };
    },
    updateCustomerArchiveStatus: (state, action) => {
      state.customers[action.payload.id] = {
        ...state.customers[action.payload.id],
        exclude_from_metrics: action.payload.exclude_from_metrics,
      };
    },
    setMarkPrice: (state, action) => {
      state.markPrice = action.payload;
    },
    setTradeMetrics: (state, action) => {
      state.tradeMetrics = action.payload;
    },
    setOrderSubmissions: (state, action) => {
      state.orderSubmissions = action.payload;
    },
    setOrderSubmissionsTotalCount: (state, action) => {
      state.orderSubmissionsTotalCount = action.payload;
    },
  },
});

export const {
  setCustomerData,
  setTradeMetrics,
  setMarkPrice,
  setOrderSubmissions,
  setOrderSubmissionsTotalCount,
  updateCustomerArchiveStatus,
} = dataSlice.actions;

export default dataSlice.reducer;
