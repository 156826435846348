import { createSlice } from "@reduxjs/toolkit";
import { filter } from "lodash";
import createUid from "components/ui/utils/createUid";

interface Notification {
  id: string;
  props: any;
  title: string;
  children: string;
}

const initialState = {
  notifications: [] as Notification[],
};

export const notificationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.notifications = [
        ...state.notifications,
        { ...action.payload, id: createUid() },
      ];
    },
    notificationPushed: (state, action) => {
      state.notifications = filter(state.notifications, (nt) => {
        return nt.id !== action.payload;
      });
    },
  },
});

export const { addNotification, notificationPushed } =
  notificationSlice.actions;

export default notificationSlice.reducer;
