import { useSelector, useDispatch } from "react-redux";
import { setUser, userLoggedOut } from "store/auth/userSlice";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQueryParams from "./useQuery";
import {
  apiSignIn,
  apiSignOut,
  apiSignUp,
  googleAuthSignInUp,
  SSOSignIn,
} from "services/SuperTokensService";
import { userMetadataPayloadTransform } from "../transforms/superTokens";
import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQueryParams();

  const { signedIn } = useSelector((state) => state.auth.session);

  const signIn = async (values) => {
    try {
      const response = await apiSignIn(values);

      if (response.status === "FIELD_ERROR") {
        response.formFields.forEach((formField) => {
          if (formField.id === "email") {
            return handleError(formField.error);
          }
        });
      } else if (response.status === "WRONG_CREDENTIALS_ERROR") {
        return handleError("Invalid Email or Password.");
      } else {
        return handleSignIn(response);
      }
    } catch (err) {
      if (err.isSuperTokensGeneralError === true) {
        return handleError(err.message);
      } else {
        return handleError(
          err?.response?.data?.message || err.message || err.toString()
        );
      }
    }
  };

  const signUp = async (values) => {
    try {
      const response = await apiSignUp(values);

      if (response.status === "FIELD_ERROR") {
        return handleFieldErrors(response.formFields);
      } else {
        return handleSignIn(response);
      }
    } catch (err) {
      if (err.isSuperTokensGeneralError === true) {
        return handleErrors([err.message || err.toString()]);
      } else {
        return handleErrors([
          err?.response?.data?.message || err.message || err.toString(),
        ]);
      }
    }
  };

  const googleSignIn = async () => {
    try {
      await googleAuthSignInUp();
    } catch (err) {
      if (err.isSuperTokensGeneralError === true) {
        return handleError(err.message);
      } else {
        return handleError(
          err?.response?.data?.message || err.message || err.toString()
        );
      }
    }
  };

  const handleSSOSignInCallback = async () => {
    try {
      const response = await SSOSignIn();
      if (response.status === "OK") {
        return handleSignIn(response);
      } else {
        return handleError(
          "No email provided by social login. Please use another form of login"
        );
      }
    } catch (err) {
      if (err.isSuperTokensGeneralError === true) {
        return handleError(err.message || err.toString());
      } else {
        return handleError(
          err?.response?.data?.message || err.message || err.toString()
        );
      }
    }
  };

  const handleSignIn = async (response) => {
    let json = await response.fetchResponse.json();
    let user = get(json, "user", undefined);

    if (user) {
      dispatch(onSignInSuccess());
      dispatch(setUser(userMetadataPayloadTransform(user)));
    }
    const redirectUrl = query.get(REDIRECT_URL_KEY);
    navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
    return {
      status: "success",
      message: "",
    };
  };

  const handleSignOut = () => {
    navigate(appConfig.unAuthenticatedEntryPath);
    dispatch(onSignOutSuccess());
    dispatch(userLoggedOut());
  };

  const signOut = async () => {
    await apiSignOut();
    handleSignOut();
  };

  const handleFailedToFetch = (message) => {
    if (message === "Failed to fetch") {
      return "Unable to connect to server. Please check your connection or try again later.";
    } else {
      return message;
    }
  };

  const handleError = (message) => {
    message = handleFailedToFetch(message);
    return {
      status: "failed",
      message: message,
    };
  };

  const handleErrors = (messages) => {
    messages = messages.map((message) => {
      return handleFailedToFetch(message);
    });

    return {
      status: "failed",
      messages: messages,
    };
  };

  const handleFieldErrors = (formFields) => {
    let messages = [];
    formFields.forEach((formField) => {
      messages.push(formField.error);
    });
    return handleErrors(messages);
  };

  return {
    signedIn: signedIn, // Not eniterly reliable as session could have expired / been invalidated. Any API calls will validate that the session still exists.
    signIn,
    signUp,
    signOut,
    googleSignIn,
    handleSSOSignInCallback,
  };
}

export const useSSOCallbackHandler = () => {
  const { handleSSOSignInCallback } = useAuth();

  return useQuery({
    queryKey: ["handleSSOCallback"],
    queryFn: handleSSOSignInCallback,
  });
};

export default useAuth;
