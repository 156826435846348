import { theme } from "twin.macro";

const twColor = theme`colors`;

export const COLOR_1 = twColor.green["500"];
export const COLOR_2 = twColor.rose["600"];
export const COLOR_3 = twColor.orange["500"];
export const COLOR_4 = twColor.pink["500"];
export const COLOR_5 = twColor.violet["500"];
export const COLOR_6 = twColor.cyan["500"];
export const COLOR_7 = twColor.fuchsia["500"];
export const COLOR_8 = twColor.purple["500"];
export const COLOR_9 = twColor.emerald["500"];
export const COLOR_10 = twColor.blue["500"];
export const COLOR_11 = twColor.indigo["500"];
export const COLOR_12 = twColor.teal["500"];
export const COLOR_13 = twColor.red["500"];
export const COLOR_14 = twColor.lime["500"];
export const COLOR_15 = twColor.yellow["500"];
export const COLOR_16 = twColor.stone["500"];
export const COLOR_17 = twColor.slate["500"];
export const COLOR_18 = twColor.pink["400"];
export const COLOR_19 = twColor.zinc["500"];
export const COLOR_20 = twColor.amber["400"];
export const COLOR_21 = twColor.red["500"];
export const COLOR_22 = twColor.sky["500"];

export const COLOR_1_LIGHT = twColor.indigo["100"];
export const COLOR_2_LIGHT = twColor.blue["100"];
export const COLOR_3_LIGHT = twColor.emerald["100"];
export const COLOR_4_LIGHT = twColor.amber["100"];
export const COLOR_5_LIGHT = twColor.red["100"];
export const COLOR_6_LIGHT = twColor.purple["100"];
export const COLOR_7_LIGHT = twColor.cyan["100"];

export const COLORS = [
  COLOR_1,
  COLOR_2,
  COLOR_3,
  COLOR_4,
  COLOR_5,
  COLOR_6,
  COLOR_7,
  COLOR_8,
  COLOR_9,
  COLOR_10,
  COLOR_11,
  COLOR_12,
  COLOR_13,
  COLOR_14,
  COLOR_15,
  COLOR_16,
  COLOR_17,
  COLOR_18,
  COLOR_19,
  COLOR_20,
  COLOR_21,
  COLOR_22,
];

export const COLORS_LIGHT = [
  COLOR_1_LIGHT,
  COLOR_2_LIGHT,
  COLOR_3_LIGHT,
  COLOR_4_LIGHT,
  COLOR_5_LIGHT,
  COLOR_6_LIGHT,
  COLOR_7_LIGHT,
];
