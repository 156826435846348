import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { metricGroups } from "constants/botTradeMetrics.constant";
import { set } from "lodash";

interface StateStore {
  customerId: string | undefined;
  customerTableOptions: {
    limit: number;
    page: number;
    order: string;
    orderBy: string;
    query: string;
  };
  tradeMetrics: {
    startDateTime: dayjs.Dayjs;
    endDateTime: dayjs.Dayjs;
    archivedCustomers: boolean;
  };
  customerMetrics: {
    archivedCustomers: boolean;
  };
  activeMetrics: string[];
  hiddenMetrics: string[];
  validMetrics: string[];
  hiddenMetricGroups: string[];
  summaryGraphZoom: {
    zoomed: boolean;
    minLabel: string | undefined;
    maxLabel: string | undefined;
    originalStartDateTime: dayjs.Dayjs | undefined;
    originalEndDateTime: dayjs.Dayjs | undefined;
  };
  orderSubmissionTableOptions: {
    limit: number;
    offset: number;
    useCustomerView: boolean;
  };
}

const initialState: StateStore = {
  customerId: undefined,
  customerTableOptions: {
    limit: 10,
    page: 1,
    order: "desc",
    orderBy: "created_at",
    query: "",
  },
  tradeMetrics: {
    startDateTime: dayjs().subtract(1, "hour"),
    endDateTime: dayjs(),
    archivedCustomers: false,
  },
  customerMetrics: {
    archivedCustomers: false,
  },
  activeMetrics: ["placed_count"],
  hiddenMetrics: ["inactive_subscription_count"],
  validMetrics: [],
  hiddenMetricGroups: ["not_subscribed"],
  summaryGraphZoom: {
    // Most of these are unused now. I reverted dateRange update on zoom logic, but I'm keeping them here for now.
    zoomed: false,
    minLabel: undefined,
    maxLabel: undefined,
    originalStartDateTime: undefined,
    originalEndDateTime: undefined,
  },
  orderSubmissionTableOptions: {
    limit: 10,
    offset: 0,
    useCustomerView: false,
  },
};

export const stateSlice = createSlice({
  name: "state",
  initialState,
  reducers: {
    setCustomerId: (state, action) => {
      state.customerId = action.payload.customerId;
    },
    resetCustomerId: (state) => {
      state.customerId = undefined;
    },
    setCustomerTableOptions: (state, action) => {
      state.customerTableOptions = {
        ...state.customerTableOptions,
        ...action.payload,
      };
    },
    resetCustomerTableOptions: (state) => {
      state.customerTableOptions = initialState.customerTableOptions;
    },
    setTradeMetricsDateRange: (state, action) => {
      state.tradeMetrics.startDateTime = action.payload.startDateTime;
      state.tradeMetrics.endDateTime = action.payload.endDateTime;
    },
    setHiddenMetrics: (state, action) => {
      state.hiddenMetrics = action.payload;
    },
    unhideMetricsForGroup: (state, action) => {
      state.hiddenMetrics = state.hiddenMetrics.filter(
        (m) => !metricGroups[action.payload].includes(m)
      );
    },
    hideMetricsForGroup: (state, action) => {
      state.hiddenMetrics = [
        ...state.hiddenMetrics,
        ...metricGroups[action.payload],
      ];
    },
    setValidMetrics: (state, action) => {
      state.validMetrics = action.payload;
    },
    setHiddenMetricGroups: (state, action) => {
      state.hiddenMetricGroups = action.payload;
    },
    addHiddenMetricGroups: (state, action) => {
      state.hiddenMetricGroups = [
        ...state.hiddenMetricGroups,
        ...action.payload,
      ];
    },
    removeHiddenMetricGroup: (state, action) => {
      state.hiddenMetricGroups = state.hiddenMetricGroups.filter(
        (group) => group !== action.payload
      );
    },
    setSummaryGraphZoomOptions: (state, action) => {
      state.summaryGraphZoom = action.payload;
    },
    resetSummaryGraphZoom: (state) => {
      state.summaryGraphZoom = {
        ...initialState.summaryGraphZoom,
      };
    },
    setActiveMetrics: (state, action) => {
      state.activeMetrics = action.payload;
    },
    addActiveMetric: (state, action) => {
      state.activeMetrics = [...state.activeMetrics, action.payload];
    },
    removeActiveMetric: (state, action) => {
      state.activeMetrics = state.activeMetrics.filter(
        (metric) => metric !== action.payload
      );
    },
    setOrderSubmissionsTableOptions: (state, action) => {
      state.orderSubmissionTableOptions = {
        ...state.orderSubmissionTableOptions,
        ...action.payload,
      };
    },
    setArchivedCustomers: (state, action) => {
      state.tradeMetrics.archivedCustomers = action.payload;
    },
    setCustomerMetricsArchivedCustomers: (state, action) => {
      state.customerMetrics.archivedCustomers = action.payload;
    },
  },
});

export const {
  setCustomerId,
  resetCustomerId,
  setCustomerTableOptions,
  resetCustomerTableOptions,
  setTradeMetricsDateRange,
  setHiddenMetrics,
  unhideMetricsForGroup,
  hideMetricsForGroup,
  setValidMetrics,
  setHiddenMetricGroups,
  addHiddenMetricGroups,
  removeHiddenMetricGroup,
  setSummaryGraphZoomOptions,
  resetSummaryGraphZoom,
  setActiveMetrics,
  addActiveMetric,
  removeActiveMetric,
  setOrderSubmissionsTableOptions,
  setArchivedCustomers,
  setCustomerMetricsArchivedCustomers,
} = stateSlice.actions;

export default stateSlice.reducer;
