import { combineReducers } from "redux";
import theme from "./theme/themeSlice";
import auth from "./auth";
import base from "./base";
import locale from "./locale/localeSlice";
import navigation from "./navigation/navigationSlice";
import notification from "./notification/notificationSlice";
import data from "./data/dataSlice";
import state from "./state/stateSlice";
import product from "./product/productSlice";

export interface RootState {
  theme: ReturnType<typeof theme>;
  auth: ReturnType<typeof auth>;
  base: ReturnType<typeof base>;
  locale: ReturnType<typeof locale>;
  navigation: ReturnType<typeof navigation>;
  notification: ReturnType<typeof notification>;
  data: ReturnType<typeof data>;
  state: ReturnType<typeof state>;
  product: ReturnType<typeof product>;
}

const rootReducer =
  (asyncReducers = {}) =>
  (_state, action) => {
    const combinedReducer = combineReducers({
      theme,
      auth,
      base,
      locale,
      navigation,
      notification,
      data,
      state,
      product,
      ...asyncReducers,
    });
    return combinedReducer(_state, action);
  };

export default rootReducer;
