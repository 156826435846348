import React, { useEffect } from "react";
import { BrowserRouter, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onNavigateSuccess } from "store/navigation/navigationSlice";
import redirectPath from "utils/navigation";

// React Router Dom BrowserRouter with a NavigationConsumer child component that
// reads redux state and triggers navigation to the path specified in the state.
// This is useful for cases where you want to trigger navigation from any non
// react component / custom hook file.

const Router = ({ children }) => {
  const NavigationConsumer = ({ children }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const {
      to: navigatePath,
      options,
      appendRedirectUrl,
    } = useSelector((state) => state.navigation);

    useEffect(() => {
      if (navigatePath) {
        const to = `${navigatePath}${
          appendRedirectUrl ? `?${redirectPath(location)}` : ""
        }`;
        navigate(to, options);
        dispatch(onNavigateSuccess());
      }
    }, [navigatePath]);

    return children;
  };

  return (
    <BrowserRouter>
      <NavigationConsumer>{children}</NavigationConsumer>
    </BrowserRouter>
  );
};

export default Router;
