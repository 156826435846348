import { REDIRECT_URL_KEY } from "constants/app.constant";

const redirectPath = (location) => {
  try {
    return location.pathname !== "/"
      ? `${REDIRECT_URL_KEY}=${location.pathname}`
      : "";
  } catch (err) {
    console.log(err);
    return "";
  }
};

export default redirectPath;
