import store from "store";
import { redirect } from "react-router-dom";
import { addNotification } from "store/notification/notificationSlice";
import { onSignOutSuccess } from "store/auth/sessionSlice";
import { setNavigate } from "./navigation/navigationSlice";
import { userLoggedOut } from "./auth/userSlice";
import appConfig from "configs/app.config";

// Helper functions to handle/chain multiple dispatches without implementing something like redux-saga.

export const handle401Dispatch = () => {
  store.dispatch(
    addNotification({
      props: {
        type: "warning",
        duration: 10000,
        closable: true,
      },
      title: "Unauthorized Access",
      children:
        "Your session seems to have expired. Please sign in again to continue.",
    })
  );
  store.dispatch(
    setNavigate({
      to: appConfig.unAuthenticatedEntryPath,
      options: { replace: true },
      appendRedirectUrl: true,
    })
  );
  store.dispatch(onSignOutSuccess());
  store.dispatch(userLoggedOut());
};
