import { combineReducers } from "@reduxjs/toolkit";
import common from "store/base/commonSlice";

export interface BaseState {
  common: ReturnType<typeof common>;
}

const reducer = combineReducers({
  common,
});

export default reducer;
