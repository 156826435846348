const appConfig = {
  bybitPublicApiUrl: {
    production: "https://api.bybit.com/v2/public/",
    development: "https://api-testnet.bybit.com/v2/public/",
  },
  apiPrefix: "/api",
  authenticatedEntryPath: "/admin/home",
  unAuthenticatedEntryPath: "/admin/sign-in",
  tourPath: "/admin",
  locale: "en",
};
export const systemUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:3000";
  }

  return process.env.REACT_APP_URL;
};
export const apiUrl = () => {
  return `${systemUrl()}/api`;
};

export const siteUrl = () => {
  return `${systemUrl()}/admin`;
};

export const graphqlUrl = () => {
  return `${apiUrl()}/graphql`;
};

export const bybitPublicApiUrl = () => {
  return appConfig.bybitPublicApiUrl[process.env.NODE_ENV || "development"];
};

export default appConfig;
