import Menu from "./Menu";
import MenuItem from "./MenuItem";
import MenuCollapse from "./MenuCollapse";
import MenuGroup from "./MenuGroup";

Menu.MenuItem = MenuItem;
Menu.MenuCollapse = MenuCollapse;
Menu.MenuGroup = MenuGroup;

export default Menu;
