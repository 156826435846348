import { handle401Dispatch } from "store/helpers";
import { toast, toastNotification } from "components/ui";

export const handle401 = () => {
  handle401Dispatch();
};

export const handle403 = () => {
  toastNotification(
    "Forbidden",
    "You do not have the necessary permissions to access the requested resource.",
    {
      type: "danger",
    }
  );
};

export const handle404 = () => {
  toastNotification(
    "Not Found",
    "The resource you are looking for does not exist.",
    { type: "danger" }
  );
};

export const handle500 = () => {
  toastNotification("Server Error", "Something went wrong on the server.", {
    type: "danger",
  });
};

export const handle502 = () => {
  toastNotification("Bad Gateway", "Something went wrong on the server.", {
    type: "danger",
  });
};

export const handleStatusCodeError = (statusCode, error = undefined) => {
  switch (statusCode) {
    case 401:
      handle401();
      break;
    case 403:
      handle403();
      break;
    case 404:
      handle404();
      break;
    case 500:
      handle500();
      break;
    case 502:
      handle502();
      break;
    default:
      toastNotification(
        "Error",
        "Something went wrong. See console for more information",
        { type: "danger" }
      );
      break;
  }
  if (error) {
    console.error(`Error Status Code ${statusCode}: ${error}`);
  }
};
