import { THEME_ENUM } from "../constants/theme.constant";

/**
 * Since some configurations need to be match with specific themes,
 * we recommend to use the configuration that generated from demo.
 */

// interface for theme config
export interface ThemeConfig {
  themeColor: string;
  direction: string;
  mode: string;
  primaryColorLevel: number;
  cardBordered: boolean;
  panelExpand: boolean;
  controlSize: string;
  navMode: string;
  layout: {
    type: string;
    sideNavCollapse: boolean;
  };
}

export const themeConfig: ThemeConfig = {
  themeColor: "blue",
  direction: THEME_ENUM.DIR_LTR,
  mode: THEME_ENUM.MODE_DARK,
  primaryColorLevel: 600,
  cardBordered: true,
  panelExpand: false,
  controlSize: "md",
  navMode: THEME_ENUM.NAV_MODE_DARK,
  layout: {
    type: THEME_ENUM.LAYOUT_TYPE_CLASSIC,
    sideNavCollapse: false,
  },
};
