import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { graphqlUrl } from "configs/app.config";
import { onError } from "@apollo/client/link/error";
import { handleStatusCodeError } from "utils/httpStatusCodeHandlers";
import { toastNotification } from "components/ui";

const handleCustomerIdNotProvidedError = () => {
  toastNotification(
    "Request could not be completed",
    "Please ensure that the customer ID has been set on the customer details page.",
    { type: "warning" }
  );
};

const handleGraphQLErrors = (graphQLErrors) => {
  let pushedNotifcation = false;

  try {
    const errors = graphQLErrors.map(({ message, path }) => {
      if (message === "customer_id needs to be set") {
        handleCustomerIdNotProvidedError();
        pushedNotifcation = true;
      }

      return `ApolloError: Error at ${path.join(".")}: ${message}`;
    });

    errors.forEach((err) => console.error(err));
  } catch (e) {
    console.error("GraphQL Errors:", graphQLErrors);
  } finally {
    if (!pushedNotifcation) {
      toastNotification(
        "Some data could not be fetched",
        "This could be because the customer has not set their exchange key yet. See console for more information.",
        { type: "warning" }
      );
    }
  }
};

const httpLink = new HttpLink({ uri: graphqlUrl() });

const logoutLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    handleGraphQLErrors(graphQLErrors);
  }

  if (networkError) {
    handleStatusCodeError(networkError.statusCode, networkError);
  }
});

const paginatedCacheRead = (existing, { args: { page, limit } }) => {
  // A read function should always return undefined if existing is
  // undefined. Returning undefined signals that the field is
  // missing from the cache, which instructs Apollo Client to
  // fetch its value from your GraphQL server.
  const paginatedItems =
    existing && existing.slice(page * limit - limit, page * limit);

  if (paginatedItems && paginatedItems.length) return paginatedItems;

  return undefined;
};

const paginatedCacheMerge = (
  existing,
  incoming,
  { args: { page = 1, limit = 10 } }
) => {
  const merged = existing ? existing.slice(0) : [];
  for (let i = 0; i < incoming.length; ++i) {
    merged[page * limit - limit + i] = incoming[i];
  }
  return merged;
};

const typePolicies = {
  addTypename: false,
  typePolicies: {
    Customer: {
      fields: {
        order_submissions: {
          read: paginatedCacheRead,
          merge: paginatedCacheMerge,
        },
      },
    },
    Exchange: {
      fields: {
        orders: {
          read: paginatedCacheRead,
          merge: paginatedCacheMerge,
        },
      },
    },
  },
};

const apolloClient = new ApolloClient({
  uri: graphqlUrl(),
  cache: new InMemoryCache(typePolicies),
  link: logoutLink.concat(httpLink),
});

export { apolloClient };
