import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationPushed } from "store/notification/notificationSlice";
import { toastNotification } from "components/ui";

// This is a wrapper component that reads redux state and pushes any notifications
// to the toast component to display. This is a weird way of triggering and displaying
// the notifications, but the easiest way to implement somewhat persisting
// notifications using the toast functionality that is already built into the app.

// Usage example in client/src/services/ApolloService.js
// If graphql gets a 401 response the user is redirected to the sign in page and a notification is displayed.

const NotificationConsumer = ({ children }) => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notification);

  useEffect(() => {
    if (notifications.length > 0) {
      notifications.forEach((notification) => {
        toastNotification(
          notification.title,
          notification.children,
          notification.props
        );
        dispatch(notificationPushed(notification.id));
      });
    }
  }, [notifications]);

  return children;
};

export default NotificationConsumer;
