import { combineReducers } from "@reduxjs/toolkit";
import session from "./sessionSlice";
import user from "./userSlice";

export interface AuthState {
  session: ReturnType<typeof session>;
  user: ReturnType<typeof user>;
}

const reducer = combineReducers({
  session,
  user,
});

export default reducer;
