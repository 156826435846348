import React, { useEffect, useState } from "react";
import SuperTokens from "supertokens-web-js";
import Session from "supertokens-web-js/recipe/session";
import ThirdPartyEmailPassword, {
  emailPasswordSignIn,
  emailPasswordSignUp,
  getAuthorisationURLWithQueryParamsAndSetState,
  thirdPartySignInAndUp,
} from "supertokens-web-js/recipe/thirdpartyemailpassword";
import {
  UserRoleClaim /*PermissionClaim*/,
} from "supertokens-web-js/recipe/userroles";

import { apiUrl, siteUrl } from "configs/app.config";
import { APP_NAME } from "../constants/app.constant";

export const initSuperTokens = () => {
  SuperTokens.init({
    appInfo: {
      appName: APP_NAME,
      apiDomain: apiUrl(),
      websiteDomain: siteUrl(),
      apiBasePath: "/api/auth",
      websiteBasePath: "/admin/sign-in",
    },
    recipeList: [
      ThirdPartyEmailPassword.init({
        signInAndUpFeature: {
          signUpForm: {
            formFields: [
              {
                id: "fullName",
                label: "Full name",
                placeholder: "First name and last name",
              },
            ],
          },
        },
      }),
      Session.init(),
    ],
  });
};

export const useSuperTokensAuthenticated = () => {
  const [authenticated, setAuthenticated] = useState(undefined);

  useEffect(() => {
    const checkAuthenticated = async () => {
      setAuthenticated(await isAuthenticated());
    };
    checkAuthenticated();
  });

  return authenticated;
};

export const isAuthenticated = async () => {
  return await Session.doesSessionExist();
};

export const apiSignIn = async (values) => {
  const { email, password } = values;

  return await emailPasswordSignIn({
    formFields: [
      {
        id: "email",
        value: email,
      },
      {
        id: "password",
        value: password,
      },
    ],
  });
};

export const apiSignUp = async (values) => {
  const { email, password, fullName } = values;

  return await emailPasswordSignUp({
    formFields: [
      {
        id: "email",
        value: email,
      },
      {
        id: "fullName",
        value: fullName,
      },
      {
        id: "password",
        value: password,
      },
    ],
  });
};

export const apiSignOut = async () => {
  return await Session.signOut();
};

export const googleAuthSignInUp = async () => {
  const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
    thirdPartyId: "google",
    frontendRedirectURI: `${siteUrl()}/sign-in/callback/google`,
  });

  // redirect the user to google for sso auth.
  window.location.assign(authUrl);
};

export const SSOSignIn = async () => {
  return await thirdPartySignInAndUp();
};

export const getUserRoles = async () => {
  return await Session.getClaimValue({ claim: UserRoleClaim });
};
