import React from "react";
import { useSelector } from "react-redux";
import { ConfigProvider } from "components/ui";
import useDarkMode from "utils/hooks/useDarkMode";
import { themeConfig } from "configs/theme.config";
import { ANT_THEME } from "constants/theme.constant";

// Ant D Theme
import { ConfigProvider as AntConfigProvider, theme as antTheme } from "antd";
import { theme as twTheme } from "twin.macro";
import { redirect } from "react-router-dom";
const { defaultAlgorithm, darkAlgorithm } = antTheme;
const twColor = twTheme`colors`;

const Theme = (props) => {
  const theme = useSelector((state) => state.theme);
  const locale = useSelector((state) => state.locale.currentLang);
  const [isDark] = useDarkMode();

  const currentTheme = {
    mode: isDark ? "dark" : "light",
    ...themeConfig,
    // ...theme,
    ...{ locale },
  };

  const themeColor = twColor[theme.themeColor][theme.primaryColorLevel];
  const antThemeConfig = isDark
    ? ANT_THEME(themeColor)["dark"]
    : ANT_THEME(themeColor)["light"];

  return (
    <AntConfigProvider
      theme={{
        algorithm: isDark ? darkAlgorithm : defaultAlgorithm,
        token: antThemeConfig,
      }}
    >
      <ConfigProvider value={currentTheme}>{props.children}</ConfigProvider>
    </AntConfigProvider>
  );
};

export default Theme;
